<template>
  <svg
    width="18"
    height="13"
    viewBox="0 0 18 13"
    fill="inherit"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.25083 2.07167H18V3.1075H7.25083V2.07167ZM7.25083 9.3225H18V10.3583H7.25083V9.3225ZM4.14333 5.17917H1.03583C0.761197 5.17889 0.497888 5.06967 0.303691 4.87548C0.109494 4.68128 0.000274225 4.41797 0 4.14333V1.03583C0.000274225 0.761197 0.109494 0.497889 0.303691 0.303692C0.497888 0.109495 0.761197 0.000274225 1.03583 0H4.14333C4.41797 0.000274225 4.68128 0.109495 4.87548 0.303692C5.06967 0.497889 5.17889 0.761197 5.17917 1.03583V4.14333C5.17889 4.41797 5.06967 4.68128 4.87548 4.87548C4.68128 5.06967 4.41797 5.17889 4.14333 5.17917ZM1.03583 1.03583V4.14333H4.14385L4.14333 1.03583H1.03583ZM4.14333 12.43H1.03583C0.761197 12.4297 0.497888 12.3205 0.303691 12.1263C0.109494 11.9321 0.000274225 11.6688 0 11.3942V8.28667C0.000274225 8.01203 0.109494 7.74872 0.303691 7.55452C0.497888 7.36033 0.761197 7.25111 1.03583 7.25083H4.14333C4.41797 7.25111 4.68128 7.36033 4.87548 7.55452C5.06967 7.74872 5.17889 8.01203 5.17917 8.28667V11.3942C5.17889 11.6688 5.06967 11.9321 4.87548 12.1263C4.68128 12.3205 4.41797 12.4297 4.14333 12.43ZM1.03583 8.28667V11.3942H4.14385L4.14333 8.28667H1.03583Z"
      fill="inherit"
    />
  </svg>
</template>
<script>
export default {
  name: 'FiveGameProvidersIcon',
}
</script>
